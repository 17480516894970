import * as React from 'react';
import { SVG } from '../../../../utils/types';

const PlantPot1: React.FC<SVG> = ({
  width = 108,
  height = 152,
  // color = '#FFFFFF',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M19.4438 100.194L27.6244 153.943H75.1456L86.1729 100.965L19.4438 100.194Z"
      fill="#08B4BD"
    />
    <path
      d="M79.5338 134.643C79.5338 134.797 67.3555 134.92 52.3406 134.92C37.3258 134.92 25.1372 134.797 25.1372 134.643C25.1372 134.489 37.3155 134.365 52.3406 134.365C67.3657 134.365 79.5338 134.489 79.5338 134.643Z"
      fill="#FAFAFA"
    />
    <path
      d="M79.7189 132.002C79.7189 132.156 67.4584 132.28 52.3408 132.28C37.2232 132.28 24.9626 132.136 24.9626 132.002C24.9626 131.868 37.2232 131.725 52.3511 131.725C67.4789 131.725 79.7189 131.848 79.7189 132.002Z"
      fill="#FAFAFA"
    />
    <path
      d="M79.6264 129.052C79.6264 129.207 67.3659 129.33 52.238 129.33C37.1101 129.33 24.8599 129.165 24.8599 129.052C24.8599 128.939 37.1204 128.775 52.238 128.775C67.3556 128.775 79.6264 128.857 79.6264 129.052Z"
      fill="#FAFAFA"
    />
    <path
      d="M33.0815 150.88C31.9271 146.416 31.1366 141.865 30.7178 137.273C31.374 139.479 31.8551 141.734 32.1566 144.015C32.648 146.275 32.9573 148.571 33.0815 150.88Z"
      fill="#FAFAFA"
    />
    <path
      d="M36.3189 150.306C35.978 148.122 35.7789 145.918 35.7228 143.708C35.4807 141.507 35.3777 139.293 35.4145 137.079C35.931 139.239 36.2204 141.447 36.2777 143.667C36.5194 145.872 36.5331 148.097 36.3189 150.306Z"
      fill="#FAFAFA"
    />
    <path
      d="M42.2079 150.306C41.5605 148.098 41.1472 145.828 40.9746 143.533C40.6152 141.26 40.4912 138.956 40.6046 136.658C41.0452 138.909 41.3541 141.184 41.5296 143.471C41.884 145.735 42.1104 148.017 42.2079 150.306Z"
      fill="#FAFAFA"
    />
    <path
      d="M46.3391 150.634C45.8894 148.367 45.6691 146.06 45.6814 143.748C45.5145 141.445 45.5868 139.131 45.8972 136.842C46.1415 139.129 46.2582 141.428 46.2466 143.728C46.4139 146.026 46.4448 148.332 46.3391 150.634Z"
      fill="#FAFAFA"
    />
    <path
      d="M53.6664 150.305C53.1449 145.982 52.9971 141.622 53.2245 137.273C53.5771 139.43 53.7422 141.614 53.7178 143.799C53.883 145.965 53.8658 148.142 53.6664 150.305Z"
      fill="#FAFAFA"
    />
    <path
      d="M59.0105 150.634C58.7553 148.393 58.6797 146.136 58.7844 143.882C58.7106 141.626 58.824 139.368 59.1236 137.13C59.4627 141.627 59.4249 146.144 59.0105 150.634Z"
      fill="#FAFAFA"
    />
    <path
      d="M64.0154 149.874C63.9667 147.513 64.1041 145.153 64.4265 142.814C64.5582 140.456 64.8778 138.112 65.3823 135.805C65.4319 138.165 65.2979 140.526 64.9815 142.865C64.8436 145.223 64.5206 147.567 64.0154 149.874Z"
      fill="#FAFAFA"
    />
    <path
      d="M70.49 149.165C70.5029 147.138 70.7723 145.122 71.2916 143.163C71.6305 141.167 72.2246 139.222 73.0593 137.377C72.7673 139.369 72.3589 141.342 71.8363 143.286C71.5059 145.271 71.0563 147.234 70.49 149.165Z"
      fill="#FAFAFA"
    />
    <path
      d="M13.9866 89.5474V99.6086C13.9866 100.129 14.0893 100.644 14.2887 101.125C14.4882 101.606 14.7806 102.043 15.1491 102.41C15.5176 102.778 15.955 103.069 16.4362 103.267C16.9174 103.465 17.4331 103.567 17.9535 103.565H86.81C87.3296 103.565 87.8441 103.463 88.3241 103.264C88.8042 103.065 89.2403 102.774 89.6078 102.406C89.9752 102.039 90.2666 101.603 90.4655 101.123C90.6643 100.643 90.7666 100.128 90.7666 99.6086V89.5474H13.9866Z"
      fill="#08B4BD"
    />
    <g opacity={0.4}>
      <path
        d="M19.9575 103.565L20.533 106.772L84.0248 111.294L85.6383 103.565H19.9575Z"
        fill="black"
      />
    </g>
    <path
      d="M24.3564 89.5987C24.3564 89.5987 -2.36403 58.7675 0.575217 57.637C3.51446 56.5065 27.2956 73.6178 27.2956 73.6178C27.2956 73.6178 6.85451 27.9157 10.9448 24.5756C15.0351 21.2356 36.6375 65.0776 36.6375 65.0776C36.6375 65.0776 33.2974 1.16442 36.6375 0.414195C39.9775 -0.336032 52.2278 64.3068 52.2278 64.3068C52.2278 64.3068 70.4285 5.22389 75.6287 5.22389C80.8289 5.22389 70.0586 62.0767 70.0586 62.0767C70.0586 62.0767 91.6404 25.665 94.9599 30.4952C98.2794 35.3255 79.719 74.6867 79.719 74.6867C79.719 74.6867 106.851 52.0154 107.96 54.9855C109.07 57.9556 78.6091 89.5473 78.6091 89.5473L24.3564 89.5987Z"
      fill="#00737D"
    />
    <path
      d="M20.6259 43.1465C20.8109 43.0643 25.5486 53.2386 31.2216 65.8691C36.8945 78.4996 41.3342 88.8075 41.1493 88.9C40.9643 88.9925 36.2163 78.8079 30.5536 66.1671C24.8909 53.5263 20.4409 43.2287 20.6259 43.1465Z"
      fill="#242E49"
    />
    <path
      d="M42.2384 48.8296C42.4337 48.8296 43.2661 57.7912 43.9958 68.8596C44.7255 79.928 45.229 88.9308 45.0235 88.941C44.818 88.9513 43.9958 79.9897 43.2661 68.911C42.5364 57.8323 42.0329 48.8913 42.2384 48.8296Z"
      fill="#242E49"
    />
    <path
      d="M72.0419 20.208C72.0261 20.4574 71.9742 20.7032 71.8877 20.9377L71.3019 22.9931C70.7469 24.8532 69.9865 27.3711 69.0615 30.4337C67.1808 36.7233 64.6424 45.428 62.0628 55.0987C59.4833 64.7694 57.3354 73.5974 55.8966 79.9795C55.1669 83.0626 54.5708 85.6525 54.1289 87.5537C53.9234 88.3759 53.7589 89.0542 53.6253 89.6091C53.5811 89.8543 53.5051 90.0927 53.3992 90.3183C53.3943 90.0725 53.422 89.8271 53.4815 89.5886C53.574 89.0233 53.6973 88.3348 53.8514 87.5332C54.1803 85.7244 54.7044 83.114 55.393 79.9076C56.7598 73.4844 58.8153 64.6358 61.4051 54.9548C63.9949 45.2738 66.6053 36.5691 68.599 30.2898C69.6267 27.2067 70.4489 24.6477 71.0655 22.9109C71.3533 22.1093 71.5897 21.4515 71.7747 20.9171C71.8374 20.6717 71.927 20.4339 72.0419 20.208Z"
      fill="#242E49"
    />
    <path
      d="M84.9396 53.1556C85.104 53.2687 79.8833 61.0587 73.2751 70.5445C66.6669 80.0302 61.179 87.6352 61.0043 87.5119C60.8295 87.3886 66.0606 79.6191 72.6687 70.1231C79.2769 60.6271 84.7751 53.0015 84.9396 53.1556Z"
      fill="#242E49"
    />
    <path
      d="M33.3797 88.9714C32.525 88.5332 31.739 87.9723 31.0468 87.3065C29.2499 85.8319 27.5345 84.2605 25.9082 82.5996C24.293 80.9483 22.7664 79.2124 21.335 77.3994C20.7171 76.6718 20.2082 75.8583 19.8242 74.9843C19.9887 74.8507 22.7737 78.2113 26.5249 82.0549C30.276 85.8986 33.5236 88.7761 33.3797 88.9714Z"
      fill="#242E49"
    />
    <path
      d="M49.6279 76.6909C49.8334 76.6909 50.2548 79.5171 50.5836 83.0524C50.9125 86.5877 51.005 89.4653 50.7994 89.4859C50.5939 89.5064 50.1725 86.6494 49.854 83.1244C49.5354 79.5993 49.4326 76.7115 49.6279 76.6909Z"
      fill="#242E49"
    />
  </svg>
);
export default PlantPot1;
