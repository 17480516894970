import { useState } from 'react';
import { useRTVIClient, useRTVIClientTransportState } from 'realtime-ai-react';

const MicrophoneButton = () => {
  const [isListening, setIsListening] = useState(false);
  const client = useRTVIClient();
  const transportState = useRTVIClientTransportState();
  const isConnected = ['connected', 'ready'].includes(transportState);

  const handleToggleMic = async () => {
    if (!client) {
        console.error('RTVI client is not initialized');
        return;
    }

    if (isListening) {
      await client.disconnect();
      setIsListening(false);
    } else {
      await client.connect();
      setIsListening(true);
    }
  };

  return (
    <div 
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}
    >
      <button
        onClick={handleToggleMic}
        style={{
          backgroundColor: isListening ? 'green' : 'gray',  // green if active, gray if inactive
          color: 'white',
          borderRadius: '50%',
          width: '60px',
          height: '60px',
          border: 'none',
          cursor: 'pointer',
          fontSize: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <i className={`fas ${isListening ? 'fa-microphone' : 'fa-microphone-slash'}`} />
      </button>

      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        {isConnected ? (
          <span style={{ color: 'green' }}>Connected and Listening</span>
        ) : (
          <span style={{ color: 'red' }}>Not Listening</span>
        )}
      </div>
    </div>
  );
};

export default MicrophoneButton;
