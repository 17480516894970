export type Features = {
    sandraVoice: boolean;
};

export type FeatureMapping = {
    [key: string]: Features;
};

const featureMapping: FeatureMapping = {
    'ovum': {
        sandraVoice: false
    },
    'abchospital': {
        sandraVoice: true
    },
    'localhost': {
        sandraVoice: true
    },
    'default': {
        sandraVoice: false
    },
  };
  
  export const getFeaturesForCustomer = () => {
    const domain = window.location.hostname;
  
    for (const key in featureMapping) {
      if (domain.includes(key)) {
        return featureMapping[key];
      }
    }
  
    return featureMapping['default'];
  };
  