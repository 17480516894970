import React from 'react';
import { goBack } from '../../utils/utils';
import { useTheme } from '../../context/ThemeContext';
const ChatHeader: React.FC = () => {
  const { theme } = useTheme();
  return (
    <div className="bg-white">
      <div
        className={`${theme.gradient} rounded-b-lg w-full h-[10px] rounded-full mt-0 md:mt-5`}
      ></div>
      <div className="flex items-center justify-between px-4 py-3 bg-gray-10 pt-5">
        <div className="flex items-center space-x-2">
          {/* Back Icon */}
          <span
            className="text-gray-600 cursor-pointer ml-[32px] text-xl font-bold"
            onClick={goBack}
          >
            ←
          </span>

          {/* Title with Icon */}
          <div className="flex items-center space-x-1">
            <span className="text-lg font-bold flex items-center">
              <span className={`text-${theme.color}-600 mx-5`}>✦</span> Ask
              Sandra
            </span>
            {/* TODO: Remove it <span className="text-sm bg-[#08B4BD1A] text-gray-600 px-2 py-1 rounded-full mx-5">General</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
