import * as React from 'react';
import { SVG } from '../../utils/types';

const MultipleMagicStar: React.FC<SVG> = ({
  width = 38,
  height = 36,
  stopColor = '#42CBD2',
  offsetStopColor = '#0699A1',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      d="M28.3333 0.0134277L30.5333 4.13509L34.6533 6.33343L30.5333 8.53176L28.3333 12.6534L26.1367 8.53176L22.015 6.33176L26.1367 4.13509L28.3333 0.0134277ZM13.3333 5.22176L17.7783 13.5551L26.1117 18.0001L17.7783 22.4451L13.335 30.7784L8.89 22.4451L0.556671 18.0001L8.89 13.5551L13.3333 5.22176ZM32.7783 25.2218L30.0017 20.0134L27.2233 25.2218L22.015 28.0001L27.2233 30.7784L30.0017 35.9868L32.7783 30.7784L37.9867 28.0001L32.7783 25.2218Z"
      fill="url(#paint0_linear_2625_22295)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2625_22295"
        x1={2.83282}
        y1={-8.45089}
        x2={42.3289}
        y2={-5.02094}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={stopColor} />
        <stop offset={1} stopColor={offsetStopColor} />
      </linearGradient>
    </defs>
  </svg>
);
export default MultipleMagicStar;
