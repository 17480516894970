import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { THEMES, Theme } from './../constants/themes';
interface ThemeContextType {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

// Theme Provider Props
interface ThemeProviderProps {
  children: ReactNode;
}

// Theme Provider Component
export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(THEMES.default);

  useEffect(() => {
    const hostname = window.location.hostname;

    const themeMapping: Record<string, Theme> = {
      'ovum.stitchfinapp.com': THEMES.ovum,
      'ovum.stage.stitchfinapp.com': THEMES.ovum,
      'ovum.uat.stitchfinapp.com': THEMES.ovum,
      'abchospital.stitchfinapp.com': THEMES.abchospital,
      'abchospital.stage.stitchfinapp.com': THEMES.abchospital,
      'abchospital.uat.stitchfinapp.com': THEMES.abchospital,
    };
    setTheme(themeMapping[hostname] || THEMES.default);
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
