import React from 'react';
import MagicStar from '../../assets/SVGs/MagicStar';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../context/ThemeContext';
interface QuestionTabsProps {
  data: string[];
}

const QuestionTabs: React.FC<QuestionTabsProps> = ({ data }) => {
  // const [activeTab, setActiveTab] = useState<[string]>(data); // Default active tab
  const navigate = useNavigate();
  const { theme } = useTheme();
  // Determine the maximum number of questions across all tabs for a consistent height

  return (
    <div className="bg-gray-100 rounded-lg w-full max-w-[500px]">
      {/* Tabs */}
      {/* <div className="flex justify-center">
        <div className="flex flex-wrap gap-4">
          {data.map((category) => (
            <button
              key={category.key}
              onClick={() => setActiveTab(category.key)}
              className={`py-2 px-4 text-sm font-medium ${
                activeTab === category.key
                  ? "border-b-4 border-teal-40 text-black-500"
                  : "text-gray-500"
              }`}
            >
              {category.key.toUpperCase()}
            </button>
          ))}
        </div>
      </div> */}

      {/* Questions */}
      <div className="flex flex-wrap justify-center gap-4 mt-8 transition-all">
        {data.map((question, index) => (
          <button
            key={index}
            style={{
              background:
                'linear-gradient(100.06deg, #DCE1E8 12.16%, #CAD3E1 95.67%)',
            }}
            className={`px-4 py-2 bg-white text-gray-800 flex rounded-lg flex-wrap justify-start items-start shadow-md hover:bg-teal-50 hover:${theme['text-600']} transition-all`}
            onClick={() => {
              navigate('/asksandra', {
                state: {
                  question: question,
                  answer: undefined,
                  date: Date.now(),
                },
              });
            }}
          >
            <span className="flex items-center">
              <MagicStar
                width={20}
                height={20}
                stopColor={theme['star-stopColor']}
                offsetStopColor={theme['star-stopOffsetColor']}
              />
              <span className="ml-2 text-start">{question}</span>
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default QuestionTabs;
