import React, { useState, useEffect, useMemo } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { customComponentNameMap } from '../../assets/SVGs/customIcons';

type ChatBubbleProps = {
  text: string;
  chatScrollUp: any;
  sender: 'user' | 'assistant';
  type: 'text' | 'button';
  setIsTypingCompleted: Function | null | undefined;
};

const ChatBubble: React.FC<ChatBubbleProps> = ({
  text,
  chatScrollUp,
  sender,
  type,
  setIsTypingCompleted,
}) => {
  const { theme } = useTheme();
  const IconComponent = customComponentNameMap[theme.logo]["Sandra"];
  const isUser = sender === 'user';
  const [displayedText, setDisplayedText] = useState(''); // Start with an empty string
  const typingSpeed = 12; // Speed of typing effect in ms

  const sanatizedTextArray = useMemo(() => {
    function splitAndConvert(input: string): string[] {
      const regex = /(<[^>]+>|.)/g;; // Match HTML tags or any single character
      const result: string[] = [];
      let match;
      while ((match = regex.exec(input)) !== null) {
        const part = match[0];
        result.push(part);
      }
      return result;
    }
    return splitAndConvert(text);
  }, [text])

  useEffect(() => {
    if (isUser) {
      // If the message is from the user, display it instantly
      setDisplayedText(text);
      return;
    }

    // Typing effect for assistant messages
    setDisplayedText(''); // Reset displayed text for each new message

    const typeCharacter = (index: number) => {
      if (index <= sanatizedTextArray.length) {
        setDisplayedText(sanatizedTextArray.slice(0, index).join(''));
        setTimeout(() => {
          typeCharacter(index + 1);
          chatScrollUp();
        }, typingSpeed);
      } else {
        setIsTypingCompleted?.(true); // Signal the completion of typing effect
      }
    };

    typeCharacter(1); // Start the typing effect
  }, [text, isUser]);

  if (isUser) {
    return (
      <div className={`flex justify-end`}>
        <div
          className={`px-4 py-2 rounded-[16px] max-w-[90%] bg-gray-70 text-white`}
        >
          {displayedText}
        </div>
      </div>
    );
  }

  return (
    <div className={`flex justify-start`}>
      <>
        <IconComponent />
        <div
          className={`px-4 py-2 rounded-[16px] max-w-[90%] text-[#5D6A85]-400 ml-3`} // bg-white
          dangerouslySetInnerHTML={{ __html: displayedText }}
        ></div>
      </>
    </div>
  );
};

export default ChatBubble;
