import React, { useState, ReactNode } from 'react';

// Define the props type for the Popup component
interface PopupProps {
  onClose?: () => void;
  children: ReactNode;
}

// Popup Component
export const Popup: React.FC<PopupProps> = ({ onClose, children }) => {
    const [show, setShow] = useState<boolean>(true);
  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white p-6 rounded-md shadow-md w-full max-w-md mx-4">
        <button
          onClick={()=> {onClose?.();setShow(false)}}
          style={{
            fontSize: "28px", 
            width: "40px",    
            height: "40px",  
            display: "flex", 
            alignItems: "center",
            justifyContent: "center",
          }}
          className="absolute top-0 right-0 m-4 text-gray-600 hover:text-gray-800"
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};