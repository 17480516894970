import MultipleMagicStar from '../../assets/SVGs/MultipleMagicStar';
import PatientBudLogo from '../../assets/SVGs/logos/PatientBudLogo';
import { useTheme } from '../../context/ThemeContext';
import { logoNameMap } from '../../assets/SVGs/logos/Logo';
export const AskSandra = () => {
  const { theme } = useTheme();
  const LogoComponent = logoNameMap[theme.logo] || PatientBudLogo;
  return (
    <>
      <div className="flex md:mt-2 flex-col flex-wrap items-center mt-4">
        <div className="mb-5">
          <LogoComponent />
        </div>
        <MultipleMagicStar
          stopColor={theme['star-stopColor']}
          offsetStopColor={theme['star-stopOffsetColor']}
        />
        <h1
          className={`text-2xl md:text-3xl font-bold text-center mt-4 ${theme['home-heading-text']}`}
        >
          Healthcare Billing Made Simple
        </h1>
        <div
          className={`text-center font-normal text-l p-2 rounded-lg space-x-2 md:text-2xl md:space-x-6 md:mt-4 md:mb-4 inline ${theme['home-subheading']} max-w-[722px]`}
        >
          {theme.text_sub_heading}
        </div>
      </div>
    </>
  );
};
