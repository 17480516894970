// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { smartSearch } from '../../utils/utils';

const SmartDropdown = ({ options, onSelect, labelStyles = '', inputStyles = '' }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    // Update filtered options based on the search term
    const timeout = setTimeout(() => {
      if (searchTerm) {
        // Filter options matching the search term
        const results = smartSearch(options, searchTerm);
        setFilteredOptions(results);
      } else {
        // Show all options if no search term
        setFilteredOptions(options);
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [searchTerm, options]);

  const handleOptionClick = (option) => {
    setSearchTerm(option); // Set the clicked option as the input value
    setShowDropdown(false); // Hide dropdown
    if (onSelect) {
      onSelect(option); // Pass the selected value to the parent component
    }
  };

  const handleFocus = () => {
    setShowDropdown(true);
    if (!searchTerm) {
      // Show all options if no search term when focused
      setFilteredOptions(options);
    }
  };

  const handleBlur = () => {
    // Close dropdown after a slight delay to allow option selection
    setTimeout(() => setShowDropdown(false), 200);
  };

  return (
    <div className="relative">
      <label
        htmlFor="insuranceCompanyName"
        className={`${labelStyles ? labelStyles : "block text-sm font-medium text-gray-700"}`}
      >
        Insurance Company Name
      </label>
      <input
        type="text"
        id="insuranceCompanyName"
        name="insuranceCompanyName"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder="Enter Company Name"
        className={`${inputStyles ? inputStyles : "mt-1 w-full p-2 border rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" }`}
        autoComplete="off"
      />
      {showDropdown && filteredOptions.length > 0 && (
        <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              className="p-2 cursor-pointer hover:bg-teal-100"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SmartDropdown;
