let DOMAIN = 'https://api.stitchfin.com';

export const domainMapping: Record<string, string> = {
    'ovum.stitchfinapp.com': 'https://api.ovum.stitchfinapp.com',
    'ovum.stage.stitchfinapp.com': 'https://api.ovum.stage.stitchfinapp.com',
    'ovum.uat.stitchfinapp.com': 'https://api.ovum.uat.stitchfinapp.com',
    'localhost': 'https://api.ovum.stage.stitchfinapp.com',
};
export const sandraEnvMapping: Record<string, string> = {
    'ovum.stitchfinapp.com': 'https://sandraprod.api.stitchfinapp.com/chat/',
    'ovum.stage.stitchfinapp.com': 'https://sandra1.api.stage.stitchfinapp.com/chat/',
    'ovum.uat.stitchfinapp.com': 'https://sandra1.api.stage.stitchfinapp.com/chat/',
    'localhost': 'https://sandra1.api.stage.stitchfinapp.com/chat/',
};
export const getDomain = () => {
    return domainMapping[window.location.hostname] || DOMAIN;
}
export const getSandraDomain = () => {
    return sandraEnvMapping[window.location.hostname] || 'https://sandra1.api.stage.stitchfinapp.com/chat/';
}
export const SESSION_TIMEOUT_DAY = 24 * 60 * 60 * 1000;
