import React, { useState } from 'react';
import ChatBubble from './ChatBubble';
// import ComponentRegistry from './ComponentRegistry';
import ChatLoading from './ChatLoading';
import { useChatContext } from '../../context/ChatContext';
import { useTheme } from '../../context/ThemeContext';
import { Popup } from '../shared/GenericPopup';
import UserEligibilityForm from '../insurance/userEligibilityForm';

type Message = {
  type: 'text' | 'button';
  content: string;
  sender: 'user' | 'assistant';
  metadata: any;
};

type MessageDisplayProps = {
  message: Message | null;
  chatScrollUp: any;
  isChatLoading?: boolean;
  setIsTypingCompleted?: Function | null;
  isTypingCompleted?: boolean;
  sessionId: string;
};

const DynamicResponseRenderer: React.FC<MessageDisplayProps> = ({
  message,
  chatScrollUp,
  isChatLoading,
  setIsTypingCompleted,
  isTypingCompleted,
  sessionId
}) => {
  const { sendMessageWithResponse } = useChatContext();
  const [completed, setCompleted] = useState<boolean>(false);
  const { theme } = useTheme();
  if (isChatLoading) {
    return <ChatLoading />;
  }
  if (!message || !message.content) {
    console.warn(
      'DynamicResponseRenderer received an invalid message:',
      message
    );
    return null;
  }
  const Placeholder: React.FC = () => null;
  const Component: React.ComponentType<any> = Placeholder;

  // const Component: React.ComponentType<any> = null;// message?.content.indexOf('Sandra') ? ComponentRegistry['UserEligibilityForm'] : null;
  return (
    <>
      <ChatBubble
        chatScrollUp={chatScrollUp}
        text={message.content}
        sender={message.sender}
        type={message.type}
        setIsTypingCompleted={(value: any) => { setIsTypingCompleted?.(value); setCompleted(true);}}

      />
      {
        completed && message?.metadata && message?.metadata?.trigger === "insurance" && (<Popup >
          <UserEligibilityForm callback={(value: any)=> {
            const res = `Successfully updated insurance information with membership id ${value.membershipId}, insurance provider name ${value.insuranceCompanyName} and Date of birth ${value.dateOfBirth}`;
            sendMessageWithResponse(res, theme.client, sessionId);
            setCompleted(false);
          }} />
        </Popup>)
      }
      {isTypingCompleted && Component ? (
        <Component
          callback={(value: any) => {
            const subs = value.primaryInsuranceSubscriber
              ? `I'm the subscriber`
              : `I'm not the subscriber`;
            const res = `My membership Id ${value.membershipId}, insurance company name is ${value.insuranceCompanyName}, ${subs}, Date of birth of insurance subsriber is ${value.dateOfBirth}`;
            sendMessageWithResponse(res, theme.client, sessionId);
          }}
        />
      ) : null}
    </>
  );
};

export default DynamicResponseRenderer;
