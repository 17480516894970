import { type PropsWithChildren } from 'react';
import { RTVIClient, Transport } from 'realtime-ai';
import { DailyTransport } from '@daily-co/realtime-ai-daily';
import { RTVIClientProvider } from 'realtime-ai-react';

const transport = new DailyTransport() as unknown as Transport;

const client = new RTVIClient({
  transport,
  params: {
    baseUrl: 'https://w686ou7pke.execute-api.us-east-1.amazonaws.com',
    endpoints: {
      connect: '/stage/sandraVoice',
    },
  },
  enableMic: true,
  enableCam: false,
});

export function RTVIProvider({ children }: PropsWithChildren) {
  return <RTVIClientProvider client={client}>{children}</RTVIClientProvider>;
}
