import * as React from 'react';
import { SVG } from '../../utils/types';

const SidebarLogo: React.FC<SVG> = ({
  width = 165,
  height = 36,
  color = '#FFFFFF',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 165 36`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.83116 26.9849C1.69543 27.069 1.5673 27.1593 1.44693 27.2548C0.514888 25.2833 0 23.1187 0 20.8477C0 11.6775 8.39466 4.24365 18.75 4.24365C29.1053 4.24365 37.5 11.6775 37.5 20.8477C37.5 21.8184 37.4059 22.7697 37.2254 23.6948C36.9617 23.7702 36.7104 23.8783 36.4792 24.0216L25.4068 30.8872C24.2318 31.6158 22.781 31.9043 21.3708 31.6841L15.666 31.0121C14.6907 30.8598 13.9786 30.1156 13.9515 29.2476C13.9285 28.0852 15.0993 27.195 16.3871 27.3908L21.9336 28.2568C23.4025 28.4862 24.7987 27.6205 25.0577 26.3197C25.2303 25.4525 21.6597 24.0849 14.3458 22.2168C11.878 21.8315 9.33716 22.3307 7.28441 23.6035L1.83116 26.9849ZM21.4319 11.7408C21.4319 13.4161 20.1969 14.7742 18.6734 14.7742C17.15 14.7742 15.915 13.4161 15.915 11.7408C15.915 10.0655 17.15 8.70743 18.6734 8.70743C20.1969 8.70743 21.4319 10.0655 21.4319 11.7408ZM15.269 20.841C16.7925 20.841 18.0275 19.4829 18.0275 17.8076C18.0275 16.1323 16.7925 14.7742 15.269 14.7742C13.7456 14.7742 12.5106 16.1323 12.5106 17.8076C12.5106 19.4829 13.7456 20.841 15.269 20.841ZM22.2349 20.841C23.7584 20.841 24.9934 19.4829 24.9934 17.8076C24.9934 16.1323 23.7584 14.7742 22.2349 14.7742C20.7115 14.7742 19.4765 16.1323 19.4765 17.8076C19.4765 19.4829 20.7115 20.841 22.2349 20.841Z"
      fill="#08B4BD"
    />
    <path
      d="M147.79 28.4212V28.118L148.21 27.9967C148.91 27.7946 149.26 27.2995 149.26 26.5113V17.0224C149.26 16.5778 149.19 16.2544 149.05 16.0523C148.91 15.83 148.64 15.6784 148.24 15.5976L147.79 15.4763V15.1732L152.92 13.4148L153.22 13.718L153.46 15.5369C154.16 14.9104 154.95 14.395 155.83 13.9908C156.71 13.5866 157.58 13.3845 158.44 13.3845C159.76 13.3845 160.77 13.7483 161.47 14.4759C162.19 15.2035 162.55 16.315 162.55 17.8106V26.5416C162.55 27.3298 162.93 27.8249 163.69 28.027L163.96 28.118V28.4212H156.88V28.118L157.27 27.9967C157.97 27.7744 158.32 27.2793 158.32 26.5113V16.8405C158.32 15.547 157.68 14.9003 156.4 14.9003C155.52 14.9003 154.56 15.3449 153.52 16.2342V26.5416C153.52 27.3298 153.87 27.8249 154.57 28.027L154.84 28.118V28.4212H147.79Z"
      fill="#3D4966"
    />
    <path
      d="M143.282 11.808C142.602 11.808 142.022 11.5957 141.542 11.1713C141.082 10.7267 140.852 10.1709 140.852 9.50395C140.852 8.81679 141.082 8.261 141.542 7.83658C142.022 7.41216 142.602 7.19995 143.282 7.19995C143.962 7.19995 144.532 7.41216 144.992 7.83658C145.452 8.261 145.682 8.81679 145.682 9.50395C145.682 10.1709 145.452 10.7267 144.992 11.1713C144.532 11.5957 143.962 11.808 143.282 11.808ZM139.742 28.421V28.1178L140.162 27.9966C140.562 27.8753 140.832 27.6934 140.972 27.4509C141.132 27.2084 141.212 26.885 141.212 26.4808V16.992C141.212 16.5675 141.132 16.2543 140.972 16.0522C140.832 15.8298 140.562 15.6783 140.162 15.5974L139.742 15.5065V15.2033L145.232 13.4147L145.532 13.7178L145.442 18.0227V26.5111C145.442 26.9153 145.512 27.2387 145.652 27.4812C145.812 27.7237 146.082 27.9056 146.462 28.0269L146.762 28.1178V28.421H139.742Z"
      fill="#3D4966"
    />
    <path
      d="M124.544 28.421V28.1179L125.084 27.936C125.484 27.7945 125.754 27.6025 125.894 27.36C126.034 27.1174 126.104 26.7941 126.104 26.3899C126.124 25.1772 126.134 23.9545 126.134 22.7217C126.134 21.4888 126.134 20.256 126.134 19.0231V17.8105C126.134 16.5979 126.134 15.3852 126.134 14.1726C126.134 12.9398 126.124 11.717 126.104 10.5044C126.104 10.1002 126.044 9.77681 125.924 9.53428C125.824 9.29176 125.584 9.09976 125.204 8.95828L124.544 8.71576V8.4126H139.064L139.274 13.445H138.974L137.264 10.2619C137.064 9.87786 136.834 9.5747 136.574 9.35239C136.314 9.13007 135.954 9.01891 135.494 9.01891H130.664C130.644 10.4539 130.634 11.9191 130.634 13.4147C130.634 14.9103 130.634 16.3958 130.634 17.8711H133.754C134.214 17.8711 134.554 17.7701 134.774 17.568C134.994 17.3659 135.204 17.0728 135.404 16.6888L136.034 15.5368H136.334V20.8421H136.034L135.374 19.6901C135.174 19.3263 134.964 19.0332 134.744 18.8109C134.544 18.5886 134.214 18.4774 133.754 18.4774H130.634C130.634 19.8922 130.634 21.2463 130.634 22.5398C130.634 23.813 130.644 25.0661 130.664 26.2989C130.664 27.0871 131.044 27.6025 131.804 27.845L132.704 28.1179V28.421H124.544Z"
      fill="#3D4966"
    />
    <path
      d="M107.471 28.421V28.1179L107.831 27.9966C108.551 27.7541 108.911 27.2488 108.911 26.4808V10.2922C108.911 9.86778 108.841 9.55452 108.701 9.35242C108.561 9.15031 108.291 8.99873 107.891 8.89768L107.441 8.77642V8.47326L112.721 7.16968L113.201 7.44252L113.081 11.717V15.5065C113.781 14.9204 114.531 14.4253 115.331 14.021C116.151 13.5966 117.051 13.3844 118.031 13.3844C119.331 13.3844 120.361 13.7583 121.121 14.5061C121.901 15.2539 122.291 16.4059 122.291 17.9621V26.5112C122.291 26.9154 122.371 27.2387 122.531 27.4813C122.691 27.7238 122.971 27.9057 123.371 28.0269L123.611 28.1179V28.421H116.591V28.1179L116.951 28.0269C117.671 27.7844 118.031 27.269 118.031 26.4808V16.9617C118.031 16.1533 117.891 15.5975 117.611 15.2943C117.351 14.9912 116.881 14.8396 116.201 14.8396C115.761 14.8396 115.281 14.9305 114.761 15.1124C114.241 15.2741 113.711 15.5672 113.171 15.9916V26.5415C113.171 27.3297 113.531 27.8349 114.251 28.0573L114.491 28.1179V28.421H107.471Z"
      fill="#3D4966"
    />
    <path
      d="M100.942 28.8759C99.5816 28.8759 98.3416 28.5828 97.2216 27.9967C96.1216 27.3904 95.2416 26.5214 94.5816 25.3896C93.9416 24.2376 93.6216 22.8329 93.6216 21.1757C93.6216 19.5386 93.9716 18.1441 94.6716 16.9921C95.3916 15.8199 96.3416 14.9306 97.5216 14.3243C98.7216 13.6978 100.032 13.3845 101.452 13.3845C102.612 13.3845 103.592 13.5765 104.392 13.9605C105.192 14.3445 105.802 14.8498 106.222 15.4763C106.642 16.0826 106.852 16.7193 106.852 17.3862C106.852 17.9521 106.682 18.3967 106.342 18.7201C106.002 19.0233 105.562 19.1748 105.022 19.1748C104.442 19.1748 103.972 18.9626 103.612 18.5382C103.252 18.1138 103.042 17.558 102.982 16.8708C102.962 16.4262 102.972 16.0523 103.012 15.7492C103.052 15.4258 103.052 15.1024 103.012 14.779C102.932 14.4355 102.802 14.2132 102.622 14.1121C102.462 13.9908 102.232 13.9302 101.932 13.9302C100.712 13.9302 99.7616 14.4658 99.0816 15.5369C98.4216 16.6081 98.0916 18.3159 98.0916 20.6603C98.0916 22.7016 98.4616 24.2679 99.2016 25.3593C99.9616 26.4304 101.122 26.966 102.682 26.966C103.622 26.966 104.382 26.7942 104.962 26.4506C105.542 26.107 106.102 25.5917 106.642 24.9045L106.942 25.147C106.442 26.3395 105.672 27.259 104.632 27.9058C103.592 28.5525 102.362 28.8759 100.942 28.8759Z"
      fill="#3D4966"
    />
    <path
      d="M88.7496 28.8758C87.4696 28.8758 86.4696 28.5524 85.7496 27.9057C85.0496 27.259 84.6996 26.2282 84.6996 24.8135V14.4455H82.6296V14.1423L83.3496 14.0211C83.9696 13.8796 84.4996 13.6775 84.9396 13.4148C85.3996 13.152 85.8496 12.7983 86.2896 12.3537L88.7496 9.59497H89.0496L88.9596 13.8392H92.3196V14.4455H88.9296V25.3592C88.9296 26.0666 89.0796 26.6021 89.3796 26.9659C89.6996 27.3297 90.0996 27.5116 90.5796 27.5116C90.9996 27.5116 91.3696 27.4106 91.6896 27.2084C92.0096 26.9861 92.3296 26.6931 92.6496 26.3293L92.9496 26.6324C92.5496 27.3196 91.9996 27.8653 91.2996 28.2695C90.5996 28.6737 89.7496 28.8758 88.7496 28.8758Z"
      fill="#3D4966"
    />
    <path
      d="M78.5415 11.808C77.8615 11.808 77.2815 11.5957 76.8015 11.1713C76.3415 10.7267 76.1115 10.1709 76.1115 9.50395C76.1115 8.81679 76.3415 8.261 76.8015 7.83658C77.2815 7.41216 77.8615 7.19995 78.5415 7.19995C79.2215 7.19995 79.7915 7.41216 80.2515 7.83658C80.7115 8.261 80.9415 8.81679 80.9415 9.50395C80.9415 10.1709 80.7115 10.7267 80.2515 11.1713C79.7915 11.5957 79.2215 11.808 78.5415 11.808ZM75.0015 28.421V28.1178L75.4215 27.9966C75.8215 27.8753 76.0915 27.6934 76.2315 27.4509C76.3915 27.2084 76.4715 26.885 76.4715 26.4808V16.992C76.4715 16.5675 76.3915 16.2543 76.2315 16.0522C76.0915 15.8298 75.8215 15.6783 75.4215 15.5974L75.0015 15.5065V15.2033L80.4915 13.4147L80.7915 13.7178L80.7015 18.0227V26.5111C80.7015 26.9153 80.7715 27.2387 80.9115 27.4812C81.0715 27.7237 81.3415 27.9056 81.7215 28.0269L82.0215 28.1178V28.421H75.0015Z"
      fill="#3D4966"
    />
    <path
      d="M70.1016 28.8758C68.8216 28.8758 67.8216 28.5524 67.1016 27.9057C66.4016 27.259 66.0516 26.2282 66.0516 24.8135V14.4455H63.9816V14.1423L64.7016 14.0211C65.3216 13.8796 65.8516 13.6775 66.2916 13.4148C66.7516 13.152 67.2016 12.7983 67.6416 12.3537L70.1016 9.59497H70.4016L70.3116 13.8392H73.6716V14.4455H70.2816V25.3592C70.2816 26.0666 70.4316 26.6021 70.7316 26.9659C71.0516 27.3297 71.4516 27.5116 71.9316 27.5116C72.3516 27.5116 72.7216 27.4106 73.0416 27.2084C73.3616 26.9861 73.6816 26.6931 74.0016 26.3293L74.3016 26.6324C73.9016 27.3196 73.3516 27.8653 72.6516 28.2695C71.9516 28.6737 71.1016 28.8758 70.1016 28.8758Z"
      fill="#3D4966"
    />
    <path
      d="M55.86 28.9667C54.76 28.9667 53.64 28.8253 52.5 28.5423C51.36 28.2796 50.41 27.9259 49.65 27.4813L49.8 23.3583H50.1L51.03 25.3288C51.31 25.8947 51.61 26.4 51.93 26.8446C52.25 27.2691 52.69 27.6025 53.25 27.8451C53.65 28.0472 54.03 28.1886 54.39 28.2695C54.77 28.3301 55.19 28.3604 55.65 28.3604C56.87 28.3604 57.83 28.0269 58.53 27.36C59.25 26.6931 59.61 25.8341 59.61 24.7832C59.61 23.7928 59.37 23.0248 58.89 22.4792C58.41 21.9133 57.64 21.3676 56.58 20.8421L55.35 20.2964C53.63 19.5284 52.28 18.6493 51.3 17.6589C50.34 16.6484 49.86 15.3145 49.86 13.6573C49.86 12.4648 50.16 11.4341 50.76 10.565C51.38 9.696 52.23 9.02905 53.31 8.56421C54.41 8.09936 55.69 7.86694 57.15 7.86694C58.21 7.86694 59.21 8.00842 60.15 8.29136C61.11 8.57431 61.94 8.95831 62.64 9.44336L62.46 13.0813H62.16L60.93 10.8076C60.59 10.1002 60.25 9.60505 59.91 9.3221C59.57 9.01894 59.18 8.80673 58.74 8.68547C58.48 8.60463 58.24 8.5541 58.02 8.53389C57.8 8.49347 57.52 8.47326 57.18 8.47326C56.16 8.47326 55.3 8.77642 54.6 9.38273C53.9 9.96884 53.55 10.7672 53.55 11.7777C53.55 12.8084 53.82 13.637 54.36 14.2636C54.9 14.8699 55.69 15.4156 56.73 15.9006L58.11 16.5069C60.03 17.3558 61.41 18.2653 62.25 19.2354C63.09 20.1853 63.51 21.4181 63.51 22.9339C63.51 24.7124 62.84 26.1676 61.5 27.2994C60.18 28.4109 58.3 28.9667 55.86 28.9667Z"
      fill="#3D4966"
    />
  </svg>
);
export default SidebarLogo;
