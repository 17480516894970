import * as React from 'react';
import { SVG } from '../../../../utils/types';

export const PlantPot2: React.FC<SVG> = ({
  width = 108,
  height = 152,
  // color = '#FFFFFF',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4438 100.194L27.6244 153.943H75.1456L86.1729 100.965L19.4438 100.194Z"
      fill="#502C3B"
    />
    <path
      d="M79.5343 134.643C79.5343 134.797 67.3559 134.92 52.3411 134.92C37.3263 134.92 25.1377 134.797 25.1377 134.643C25.1377 134.489 37.316 134.365 52.3411 134.365C67.3662 134.365 79.5343 134.489 79.5343 134.643Z"
      fill="#FAFAFA"
    />
    <path
      d="M79.7192 132.002C79.7192 132.156 67.4586 132.28 52.341 132.28C37.2234 132.28 24.9629 132.136 24.9629 132.002C24.9629 131.868 37.2234 131.725 52.3513 131.725C67.4792 131.725 79.7192 131.848 79.7192 132.002Z"
      fill="#FAFAFA"
    />
    <path
      d="M79.6269 129.052C79.6269 129.207 67.3664 129.33 52.2385 129.33C37.1106 129.33 24.8604 129.165 24.8604 129.052C24.8604 128.939 37.1209 128.775 52.2385 128.775C67.3561 128.775 79.6269 128.857 79.6269 129.052Z"
      fill="#FAFAFA"
    />
    <path
      d="M33.082 150.881C31.9276 146.416 31.1371 141.866 30.7183 137.274C31.3745 139.48 31.8556 141.734 32.1571 144.015C32.6485 146.275 32.9578 148.571 33.082 150.881Z"
      fill="#FAFAFA"
    />
    <path
      d="M36.3193 150.306C35.9785 148.122 35.7794 145.918 35.7233 143.708C35.4812 141.507 35.3782 139.293 35.415 137.079C35.9315 139.239 36.2209 141.447 36.2782 143.667C36.5199 145.872 36.5336 148.097 36.3193 150.306Z"
      fill="#FAFAFA"
    />
    <path
      d="M42.2079 150.306C41.5605 148.098 41.1472 145.828 40.9746 143.533C40.6152 141.26 40.4912 138.956 40.6046 136.658C41.0452 138.909 41.3541 141.184 41.5296 143.471C41.884 145.735 42.1104 148.017 42.2079 150.306Z"
      fill="#FAFAFA"
    />
    <path
      d="M46.3394 150.634C45.8897 148.367 45.6693 146.06 45.6816 143.748C45.5147 141.445 45.587 139.131 45.8975 136.842C46.1417 139.129 46.2584 141.428 46.2469 143.728C46.4141 146.026 46.445 148.332 46.3394 150.634Z"
      fill="#FAFAFA"
    />
    <path
      d="M53.6669 150.305C53.1454 145.982 52.9976 141.622 53.225 137.274C53.5776 139.431 53.7427 141.614 53.7183 143.8C53.8834 145.966 53.8663 148.142 53.6669 150.305Z"
      fill="#FAFAFA"
    />
    <path
      d="M59.011 150.634C58.7557 148.393 58.6801 146.136 58.7849 143.882C58.7111 141.626 58.8245 139.368 59.1241 137.13C59.4632 141.627 59.4254 146.144 59.011 150.634Z"
      fill="#FAFAFA"
    />
    <path
      d="M64.0154 149.874C63.9667 147.513 64.1041 145.153 64.4265 142.814C64.5582 140.456 64.8778 138.112 65.3823 135.805C65.4319 138.165 65.2979 140.526 64.9815 142.865C64.8436 145.223 64.5206 147.567 64.0154 149.874Z"
      fill="#FAFAFA"
    />
    <path
      d="M70.4902 149.165C70.5032 147.138 70.7725 145.122 71.2918 143.163C71.6308 141.167 72.2249 139.222 73.0595 137.377C72.7675 139.369 72.3591 141.342 71.8365 143.286C71.5062 145.271 71.0566 147.234 70.4902 149.165Z"
      fill="#FAFAFA"
    />
    <path
      d="M13.9868 89.5474V99.6086C13.9868 100.129 14.0895 100.644 14.289 101.125C14.4885 101.606 14.7808 102.043 15.1493 102.41C15.5178 102.778 15.9552 103.069 16.4365 103.267C16.9177 103.465 17.4333 103.567 17.9538 103.565H86.8102C87.3298 103.565 87.8443 103.463 88.3244 103.264C88.8044 103.065 89.2406 102.774 89.608 102.406C89.9754 102.039 90.2669 101.603 90.4657 101.123C90.6645 100.643 90.7669 100.128 90.7669 99.6086V89.5474H13.9868Z"
      fill="#502C3B"
    />
    <g opacity="0.4">
      <path
        d="M19.958 103.565L20.5335 106.772L84.0253 111.294L85.6388 103.565H19.958Z"
        fill="black"
      />
    </g>
    <path
      d="M24.3566 89.5987C24.3566 89.5987 -2.36378 58.7675 0.575461 57.637C3.51471 56.5065 27.2959 73.6178 27.2959 73.6178C27.2959 73.6178 6.85476 27.9157 10.945 24.5756C15.0353 21.2356 36.6377 65.0776 36.6377 65.0776C36.6377 65.0776 33.2977 1.16442 36.6377 0.414195C39.9778 -0.336032 52.2281 64.3068 52.2281 64.3068C52.2281 64.3068 70.4288 5.22389 75.629 5.22389C80.8292 5.22389 70.0588 62.0767 70.0588 62.0767C70.0588 62.0767 91.6407 25.665 94.9602 30.4952C98.2797 35.3255 79.7193 74.6867 79.7193 74.6867C79.7193 74.6867 106.851 52.0154 107.961 54.9855C109.071 57.9556 78.6093 89.5473 78.6093 89.5473L24.3566 89.5987Z"
      fill="#5B4074"
    />
    <path
      d="M20.6262 43.1462C20.8112 43.064 25.5489 53.2383 31.2218 65.8689C36.8948 78.4994 41.3345 88.8073 41.1495 88.8998C40.9645 88.9923 36.2165 78.8077 30.5538 66.1669C24.8912 53.5261 20.4412 43.2285 20.6262 43.1462Z"
      fill="#1B0134"
    />
    <path
      d="M42.2387 48.8296C42.4339 48.8296 43.2664 57.7912 43.996 68.8596C44.7257 79.928 45.2293 88.9308 45.0237 88.941C44.8182 88.9513 43.996 79.9897 43.2664 68.911C42.5367 57.8323 42.0331 48.8913 42.2387 48.8296Z"
      fill="#1B0134"
    />
    <path
      d="M72.0421 20.2083C72.0263 20.4577 71.9744 20.7034 71.888 20.9379L71.3022 22.9933C70.7472 24.8535 69.9867 27.3714 69.0618 30.434C67.1811 36.7235 64.6426 45.4282 62.0631 55.099C59.4835 64.7697 57.3356 73.5977 55.8968 79.9798C55.1671 83.0629 54.5711 85.6527 54.1292 87.554C53.9236 88.3761 53.7592 89.0544 53.6256 89.6094C53.5813 89.8546 53.5053 90.0929 53.3995 90.3185C53.3946 90.0727 53.4222 89.8274 53.4817 89.5888C53.5742 89.0236 53.6975 88.335 53.8517 87.5334C54.1805 85.7246 54.7047 83.1143 55.3932 79.9078C56.7601 73.4846 58.8155 64.6361 61.4053 54.9551C63.9952 45.274 66.6055 36.5694 68.5993 30.2901C69.627 27.2069 70.4492 24.648 71.0658 22.9111C71.3535 22.1095 71.5899 21.4518 71.7749 20.9174C71.8377 20.6719 71.9273 20.4341 72.0421 20.2083Z"
      fill="#1B0134"
    />
    <path
      d="M84.9401 53.1559C85.1045 53.2689 79.8837 61.059 73.2756 70.5447C66.6674 80.0304 61.1795 87.6355 61.0047 87.5121C60.83 87.3888 66.0611 79.6194 72.6692 70.1233C79.2774 60.6273 84.7756 53.0017 84.9401 53.1559Z"
      fill="#1B0134"
    />
    <path
      d="M33.3797 88.9717C32.525 88.5335 31.739 87.9726 31.0468 87.3068C29.2499 85.8321 27.5345 84.2608 25.9082 82.5999C24.293 80.9485 22.7664 79.2127 21.335 77.3997C20.7171 76.672 20.2082 75.8585 19.8242 74.9846C19.9887 74.851 22.7737 78.2115 26.5249 82.0552C30.276 85.8988 33.5236 88.7764 33.3797 88.9717Z"
      fill="#1B0134"
    />
    <path
      d="M49.6279 76.6912C49.8334 76.6912 50.2548 79.5174 50.5836 83.0527C50.9125 86.588 51.005 89.4656 50.7994 89.4861C50.5939 89.5067 50.1725 86.6497 49.854 83.1246C49.5354 79.5996 49.4326 76.7117 49.6279 76.6912Z"
      fill="#1B0134"
    />
  </svg>
);
