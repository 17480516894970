import React from 'react';
import { svgNameMap } from '../../assets/SVGs';
import { useTheme } from '../../context/ThemeContext';
import { customComponentNameMap } from '../../assets/SVGs/customIcons';

interface IProps {
  iconName: string;
  iconColor?: string;
  className?: string;
  textClass?: string;
  notificationNumber?: string;
  text: string;
  clickHandler?: () => void;
  isActive: boolean;
}

const IconWithText: React.FC<IProps> = ({
  iconName,
  iconColor,
  className,
  textClass,
  notificationNumber,
  clickHandler,
  text,
  isActive,
}) => {
  const { theme } = useTheme();
  const IconComponent = customComponentNameMap[theme.logo][iconName] || (iconName ? svgNameMap[iconName] : null)
  return (
    <div
      className={`flex items-center cursor-pointer rounded-lg ${className || ''}`}
      onClick={clickHandler}
    >
      <div
        className="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 rounded-full"
        style={!isActive ? { opacity: '50%' } : undefined}
      >
        {IconComponent && (
          <IconComponent color={iconColor ? iconColor : '#818BA0'} />
        )}
      </div>
      <div
        className={`ml-3 md:ml-2 text-[16px] md:text-[14px] ${textClass || ''}`}
      >
        <span style={!isActive ? { opacity: '50%' } : undefined}> {text} </span>
        {!isActive && (
          <span
            className={`text-${theme.p_color}-500 px-1 py-1 rounded-md text-[0.625rem] animate-pulse`}
          >
            Launching Soon
          </span>
        )}
      </div>
      {notificationNumber && (
        <div
          className={
            'ml-auto bg-[#F3B949] text-[] rounded-full w-5 h-5 md:w-6 md:h-6 flex items-center justify-center text-xs md:text-sm font-extrabold'
          }
        >
          {notificationNumber}
        </div>
      )}
    </div>
  );
};

export default IconWithText;
