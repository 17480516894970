import {
  RTVIClientAudio,
} from 'realtime-ai-react';
import { RTVIProvider } from './providers/RTVIProvider';
import MicrophoneButton from './Microphone';
import '@fortawesome/fontawesome-free/css/all.min.css';


function AppContent() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "center"
      }}
      className={`bg-gray-100 flex sm:items-center items-start pt-6`}
    >
      <MicrophoneButton />
      <RTVIClientAudio />
    </div>
  );
}

function Sandravoice() {
  return (
    <RTVIProvider>
      <AppContent />
    </RTVIProvider>
  );
}

export default Sandravoice;

