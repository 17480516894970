import React, { useState } from 'react';
import {
  UserEligibilityType,
  formatDateToYYYYMMDD,
  getPayers,
  postUserEligibilityData,
  // sendInsuranceData,
} from '../../utils/utils';
import SmartDropdown from './SmartDropDown';
import LoadingOverlay from './LoadingOverlay';
import { useTheme } from '../../context/ThemeContext';
type UserEligibilityFormType = {
  callback?: Function;
};
export default function UserEligibilityForm({
  callback,
}: UserEligibilityFormType) {
  const [loading, setLoading] = useState(false);
  const [payers, setpayers] = useState([]);
  const { theme } = useTheme();
  React.useEffect(() => {
    setLoading(true);
    const response = getPayers();
    response
      .then((responseData) => {
        const mapData = responseData.map((data: any) => data.payerName);
        setpayers(mapData || []);
        setLoading(false);
      })
      .catch((error) => {
        setpayers([]);
        setLoading(false);
        setMessage({
          type: 'error',
          text: 'Something went wrong. Please try after sometime',
        });
      });
  }, []);
  const [formData, setFormData] = useState<UserEligibilityType>({
    membershipId: '',
    insuranceCompanyName: '',
    primaryInsuranceSubscriber: true,
    dateOfBirth: '',
  });
  const [message, setMessage] = useState<{
    type: 'success' | 'error';
    text: string;
  } | null>(null);
  // @ts-ignore
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // @ts-ignore
  const handleDateChange = (e) => {
    const value = e.target.value;
    const formattedDate = value;
    setFormData((prev) => ({ ...prev, dateOfBirth: formattedDate }));
  };

  const onInsuranceChange = (value: string) => {
    setFormData((prev) => ({ ...prev, insuranceCompanyName: value }));
  };

  // @ts-ignore
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      // const firstName = await localStorage.getItem('firstName');
      // const lastName = await localStorage.getItem('lastName');
      // const email = await localStorage.getItem('email');
      // const phone = await localStorage.getItem('phone');
      // const formArgs = {
      //   firstName,
      //   email, 
      //   insuranceCompany: formData.insuranceCompanyName,
      //   lastName,
      //   insuranceMemberId: formData.membershipId,
      //   phone,
      //   dob: formData.dateOfBirth,
      // }
      const response = postUserEligibilityData({
        ...formData,
        dateOfBirth: formatDateToYYYYMMDD(formData.dateOfBirth),
      });
      // const response = sendInsuranceData(formArgs);
      response
        .then((data: any) => {
          setLoading(false);
          setMessage({
            type: 'success',
            text: 'Insurance Information Updated Successfully.',
          });
          callback && callback(formData);
        })
        .catch((err: any) => {
          setLoading(false);
          setMessage({
            type: 'error',
            text: 'Failed to update insurance details.',
          });
        });
    } catch (err) {
      console.error(err);
      setMessage({
        type: 'error',
        text: 'Failed to update insurance details.',
      });
    }
  };

  return (
    <div
      className={`w-full flex sm:items-center items-start ${!callback && `justify-center`}`}
    >
      {loading && <LoadingOverlay />}
      <form
        onSubmit={handleSubmit}
        className="bg-white p-4 rounded-lg max-w-lg w-full space-y-6"
      >
        <h2 className="text-2xl font-bold text-gray-800 text-center">
          Insurance Details Form
        </h2>
        {message && (
          <div
            className={`p-4 rounded-md mb-4 ${
              message.type === 'success'
                ? 'bg-green-100 text-green-700'
                : 'bg-red-100 text-red-700'
            }`}
          >
            {message.text}
          </div>
        )}
        <div>
          <label
            htmlFor="membershipId"
            className="block text-sm font-medium text-gray-700"
          >
            Membership ID
          </label>
          <input
            type="text"
            id="membershipId"
            name="membershipId"
            value={formData.membershipId}
            onChange={handleChange}
            placeholder="Enter Membership ID"
            className="mt-1 w-full p-2 border rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
            required
          />
        </div>
        <SmartDropdown options={payers} onSelect={onInsuranceChange} />
        <div>
          <label
            htmlFor="dateOfBirth"
            className="block text-sm font-medium text-gray-700"
          >
            Date of Birth
          </label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleDateChange}
            className="mt-1 w-full p-2 border rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
            required
          />
        </div>
        <button
          type="submit"
          className={`w-full text-white py-2 px-4 rounded-md shadow focus:outline-none focus:ring-2 focus:ring-offset-2 ${theme['button-bg']}`}
        >
          Submit
        </button>
      </form>
    </div>
  );
}
