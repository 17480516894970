import React, { useEffect } from 'react'
import { SandraQnA } from './SandraQnA';
import ArrowIcon from '../../assets/SVGs/ArrowIcon';
import { useTheme } from '../../context/ThemeContext';
import { customComponentNameMap } from '../../assets/SVGs/customIcons';
import MagicStar from '../../assets/SVGs/MagicStar';
import { useNavigate } from 'react-router-dom';
import { QUESTIONS } from './constants';
import { AskSandra } from './AskSandra';
import MultipleMagicStar from '../../assets/SVGs/MultipleMagicStar';
import OvumForm from './OvumForm';
import SidebarLogo from '../../assets/SVGs/SidebarLogo';
import { useDispatch } from 'react-redux';
import { hideSideBar } from '../../store/sidebarSlice';
import { isOvum } from '../../utils/utils';

export default function OvumLandingPage() {
  const { theme } = useTheme();
  const { Component1, Component2, Component3 } =
    customComponentNameMap[theme.logo];
  const navigate = useNavigate();

  const dispatch = useDispatch()
  
  useEffect(() => {
    if(isOvum()) {
      dispatch(hideSideBar())
    }
  }, [isOvum])
  
  return (
    <>
    <div className="min-h-[950px] md:min-h-screen overflow-hidden relative flex flex-col items-center bg-gray-10 p-4 md:p-8">
    <div className="flex justify-center items-center w-full md:justify-end md:h-auto"></div>
    <>
      <>
        <div className="flex md:mt-2 flex-col items-center mt-4">
          <MultipleMagicStar
            stopColor={theme['star-stopColor']}
            offsetStopColor={theme['star-stopOffsetColor']}
          />
          <h1
            className={`text-2xl md:text-3xl font-medium text-center mt-2 text-[${theme['p_color']}]`}
          >
            Ask me a billing question
          </h1>
          <div
            className={`text-center font-normal text-l p-2 rounded-lg space-x-2 md:text-2xl md:space-x-6 md:mt-2 md:mb-4 inline ${theme['home-subheading']} px-4 md:px-[180px]`}
          >
            Hi, I am Sandra at Ovum! Ask me any insurance, offers, pricing, estimate or bill related question!
          </div>
        </div>
      </>
      <div className="flex flex-wrap justify-center gap-4 px-4 md:px-[180px]">
        {QUESTIONS[theme.client].map((question, index) => (
          <button
            key={index}
            style={{
              background:
                'linear-gradient(100.06deg, #DCE1E8 12.16%, #CAD3E1 95.67%)',
            }}
            className={`flex flex-row items-center justify-center rounded-lg shadow-md px-4 py-2 
              bg-white text-gray-800 hover:bg-teal-50 hover:${theme['text-600']}`}
          >
            <MagicStar
              stopColor={theme['star-stopColor']}
              offsetStopColor={theme['star-stopOffsetColor']}
            />
            <span
              className="ml-2 text-sm sm:text-base lg:text-lg text-center truncate"
            >
              {question.question}
            </span>
          </button>
        ))}
      </div>
    </>
    <div className="static bottom-20 z-10 w-full max-w-[400px] md:max-w-auto md:w-auto md:min-w-[600px] md:bottom-auto">
      <OvumForm />
    </div>
    <div className="fixed bottom-20 md:bottom-0 right-45 md:right-0 w-[157px] hidden lg:flex lg:w-[257px] z-0">
      <Component3 />
    </div>
    <div className="fixed bottom-0 left-10 flex justify-end items-end hidden lg:flex">
      <Component1 />
      <div className="ml-5">
        <Component2 />
      </div>
    </div>


    <div className="absolute bottom-0 left-50 flex justify-end items-end hidden md:flex">
      <span className='flex justify-center items-center text-center font-medium gap-2 text-sm'>
        Powered by <SidebarLogo width={111} />
      </span>
    </div>
    </div>
  </>
  )
}
