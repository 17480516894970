import PlantPot1 from './paitentBud/PlantPot1';
import PlantPot2 from './paitentBud/PlantPot2';
import SandraDoc from './paitentBud/SandraDoc';
import { PlantPot1 as OvumPlant1 } from './ovum/PlantPot1';
import { PlantPot2 as OvumPlant2 } from './ovum/PlantPot2';
import { SandraDoc as OvumDoc } from './ovum/SandraDoc';
import { SandraSmall } from './ovum/SandraSmall';
import Sandra from './paitentBud/Sandra';

export const customComponentNameMap: { [key: string]: any } = {
  Ovum: {
    Component1: OvumPlant1,
    Component2: OvumPlant2,
    Component3: OvumDoc,
    Sandra: SandraSmall,
  },
  PatientBud: {
    Component1: PlantPot1,
    Component2: PlantPot2,
    Component3: SandraDoc,
    Sandra: Sandra,
  },
  AbcHospital: {
    Component1: PlantPot1,
    Component2: PlantPot2,
    Component3: SandraDoc,
    Sandra: Sandra,
  },
};
