import * as React from 'react';
import { SVG } from '../../../../utils/types';

export const PlantPot1: React.FC<SVG> = ({
  width = 123,
  height = 235,
  // color = '#FFFFFF',
  otherProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M112.357 162.605L100.273 242.002H30.0607L13.769 163.745L112.357 162.605Z"
      fill="#502C3B"
    />
    <path
      d="M23.5908 213.491C23.5908 213.726 41.5754 213.91 63.8172 213.91C86.0591 213.91 104.044 213.726 104.044 213.491C104.044 213.257 86.0591 213.089 63.8172 213.089C41.5754 213.089 23.5908 213.273 23.5908 213.491Z"
      fill="#FAFAFA"
    />
    <path
      d="M23.3228 209.586C23.3228 209.82 41.4246 210.005 63.767 210.005C86.1095 210.005 104.211 209.82 104.211 209.586C104.211 209.351 86.1095 209.183 63.767 209.183C41.4246 209.183 23.3228 209.368 23.3228 209.586Z"
      fill="#FAFAFA"
    />
    <path
      d="M23.457 205.178C23.457 205.412 41.5589 205.597 63.9013 205.597C86.2437 205.597 104.346 205.412 104.346 205.178C104.346 204.943 86.2437 204.759 63.9013 204.759C41.5589 204.759 23.457 204.943 23.457 205.178Z"
      fill="#FAFAFA"
    />
    <path
      d="M92.2109 237.476C93.1842 234.184 93.9015 230.822 94.3564 227.42C95.0745 224.081 95.5228 220.69 95.6972 217.279C94.7095 220.567 93.992 223.931 93.5518 227.336C92.8239 230.673 92.3754 234.065 92.2109 237.476Z"
      fill="#FAFAFA"
    />
    <path
      d="M87.4337 236.638C87.911 233.406 88.202 230.149 88.3053 226.883C88.6595 223.633 88.8107 220.364 88.7579 217.095C87.9923 220.281 87.5653 223.54 87.484 226.816C87.1102 230.079 87.0933 233.372 87.4337 236.638Z"
      fill="#FAFAFA"
    />
    <path
      d="M78.7178 236.638C80.3585 230.062 81.1527 223.303 81.0811 216.525C80.4424 219.85 79.9891 223.207 79.7234 226.582C79.2078 229.913 78.8721 233.271 78.7178 236.638Z"
      fill="#FAFAFA"
    />
    <path
      d="M72.5999 237.057C73.6772 230.318 73.8968 223.47 73.2535 216.676C72.8902 220.06 72.7223 223.463 72.7507 226.866C72.4981 230.257 72.4478 233.66 72.5999 237.057Z"
      fill="#FAFAFA"
    />
    <path
      d="M61.7892 236.638C62.3204 233.448 62.5727 230.218 62.5435 226.984C62.7854 223.763 62.7517 220.527 62.4429 217.312C61.9328 220.505 61.6861 223.734 61.7054 226.967C61.4736 230.188 61.5016 233.422 61.7892 236.638Z"
      fill="#FAFAFA"
    />
    <path
      d="M53.8948 237.057C54.293 233.721 54.4052 230.356 54.23 227.001C54.3398 223.668 54.1716 220.333 53.7272 217.028C53.351 220.366 53.2445 223.729 53.4087 227.085C53.2988 230.417 53.4614 233.752 53.8948 237.057Z"
      fill="#FAFAFA"
    />
    <path
      d="M46.4863 235.985C46.5698 232.499 46.368 229.012 45.8829 225.559C45.6806 222.081 45.2099 218.624 44.475 215.218C44.3912 218.704 44.593 222.19 45.0784 225.643C45.2807 229.121 45.7514 232.579 46.4863 235.985Z"
      fill="#FAFAFA"
    />
    <path
      d="M36.933 234.945C36.9205 231.952 36.5263 228.972 35.7597 226.079C35.255 223.13 34.3763 220.257 33.145 217.531C33.5898 220.472 34.1941 223.387 34.9552 226.263C35.4451 229.193 36.1055 232.092 36.933 234.945Z"
      fill="#FAFAFA"
    />
    <path
      d="M43.7043 5.9231C43.6838 6.0564 43.6838 6.19204 43.7043 6.32534C43.7043 6.62704 43.8887 6.99581 44.0228 7.44835C44.358 8.48753 44.7938 9.9122 45.3637 11.7224C45.9335 13.5326 46.6878 15.8623 47.4253 18.5273C48.1628 21.1923 49.1014 24.1926 49.9562 27.5783C50.811 30.964 51.8167 34.6347 52.6882 38.6405C53.5598 42.6464 54.4816 46.9037 55.4035 51.4292C57.0796 60.4801 58.6216 70.4865 59.6943 81.0459C60.767 91.6053 61.2028 101.746 61.3704 110.964C61.3704 115.557 61.3704 119.931 61.2531 124.038C61.1358 128.144 60.9514 131.932 60.7503 135.419C60.5491 138.905 60.2809 142.022 60.0631 144.788C59.8452 147.554 59.6105 149.917 59.3759 151.861C59.1412 153.805 59.0239 155.213 58.9233 156.32C58.9233 156.789 58.8898 157.174 58.8227 157.476C58.8227 157.744 58.8227 157.878 58.8227 157.878C58.879 157.757 58.913 157.626 58.9233 157.493C58.9233 157.191 59.0239 156.806 59.1077 156.336C59.2418 155.264 59.4597 153.772 59.7111 151.895C59.9625 150.017 60.281 147.587 60.5324 144.838C60.7838 142.089 61.1525 138.938 61.3537 135.452C61.5548 131.966 61.8397 128.161 61.9403 124.055C62.0409 119.948 62.1247 115.574 62.1582 110.948C62.0576 101.729 61.6051 91.5718 60.4821 80.9788C59.3591 70.3859 57.8339 60.346 56.1075 51.2783C55.1689 46.7529 54.3308 42.4621 53.3251 38.473C52.3195 34.4839 51.4144 30.7796 50.4925 27.4274C49.5707 24.0752 48.6321 21.0415 47.8275 18.3933C47.023 15.745 46.2855 13.4655 45.6318 11.6889C44.9782 9.91222 44.5089 8.48753 44.1569 7.44835C43.9893 7.01257 43.8552 6.64383 43.7546 6.3589C43.7474 6.21269 43.7306 6.0671 43.7043 5.9231Z"
      fill="#1B0134"
    />
    <path
      d="M56.3756 55.083C54.4423 49.357 50.5793 44.4808 45.4474 41.2888C43.7713 40.2161 41.5253 39.3445 39.6816 40.2328C37.2345 41.4228 37.0334 44.8588 37.7709 47.4903C39.0442 51.9496 41.8905 55.7963 45.7826 58.3179C49.2032 60.8519 53.487 61.9304 57.6997 61.3181"
      fill="#1B0134"
    />
    <path
      d="M60.6332 88.2869C58.823 81.4316 53.3589 71.6935 47.6769 67.4362C46.8065 66.7264 45.8038 66.1965 44.727 65.8774C44.1877 65.7155 43.6163 65.6921 43.0656 65.8092C42.5149 65.9264 42.0026 66.1805 41.5759 66.5478C41.0915 67.1907 40.7737 67.9433 40.6509 68.7388C40.528 69.5343 40.604 70.3478 40.8719 71.1068C42.0367 76.1053 44.5695 80.681 48.1868 84.3219C51.804 87.9629 56.3631 90.5255 61.3539 91.7228"
      fill="#1B0134"
    />
    <path
      d="M61.8059 117.501C58.9871 110.96 54.474 105.29 48.7323 101.075C48.1254 100.502 47.4084 100.058 46.6248 99.7697C45.8411 99.4816 45.0071 99.3556 44.1733 99.3993C41.6927 99.8351 40.5697 102.869 40.8211 105.383C41.2737 110.545 45.2293 114.702 49.3022 117.903C53.0408 120.496 57.1038 122.587 61.3869 124.122"
      fill="#1B0134"
    />
    <path
      d="M61.1524 138.52C58.428 133.523 54.6264 129.195 50.0231 125.848C49.0362 125.098 47.8887 124.588 46.6709 124.357C46.0521 124.226 45.4089 124.273 44.8157 124.493C44.2226 124.712 43.7036 125.095 43.3187 125.597C42.1958 127.273 43.3187 129.452 44.4585 131.044C48.3829 136.506 53.9412 140.579 60.3312 142.676"
      fill="#1B0134"
    />
    <path
      d="M61.7053 129.954C66.4994 124.08 71.8301 118.666 77.6282 113.78C78.0147 113.333 78.5072 112.989 79.0606 112.782C79.614 112.574 80.2107 112.508 80.7961 112.59C81.2193 112.765 81.5824 113.06 81.8413 113.438C82.1002 113.816 82.2437 114.261 82.2543 114.719C82.2544 115.641 82.0482 116.551 81.6509 117.384C79.7486 121.607 76.9196 125.347 73.3737 128.327C69.8279 131.307 65.6564 133.45 61.1689 134.597"
      fill="#1B0134"
    />
    <path
      d="M61.186 102.651L73.6897 87.4152C74.7895 85.8884 76.1652 84.581 77.7459 83.5602C78.5503 83.0753 79.4717 82.8191 80.4109 82.8191C81.3501 82.8191 82.2715 83.0753 83.0759 83.5602C83.7958 84.1591 84.3341 84.9471 84.6303 85.8355C84.9264 86.7239 84.9686 87.6774 84.752 88.5885C84.305 90.4202 83.4706 92.1348 82.3049 93.6168C79.877 97.3078 76.8057 100.532 73.2372 103.137C69.5665 105.668 66.2311 107.31 61.8229 106.657"
      fill="#1B0134"
    />
    <path
      d="M59.4595 71.8611C61.9 64.5658 66.2388 58.0517 72.0302 52.9882C72.9537 52.0592 74.1124 51.3987 75.3824 51.0775C76.6565 50.9772 77.9257 51.3239 78.9719 52.0581C80.018 52.7922 80.7757 53.8679 81.1147 55.1001C81.5162 56.4802 81.6222 57.9294 81.4258 59.3533C81.2294 60.7771 80.735 62.1435 79.9749 63.3633C78.4535 65.8064 76.459 67.9207 74.1086 69.5817C69.8975 72.7949 65.209 75.3285 60.2137 77.0906"
      fill="#1B0134"
    />
    <path
      d="M54.4648 44.4735C55.6716 35.6404 60.2641 28.2823 65.5941 21.1422C66.7171 19.6337 68.2927 18.0414 70.1364 18.3263C70.7239 18.4494 71.2712 18.718 71.728 19.1075C72.1849 19.497 72.5366 19.9949 72.7511 20.5556C73.1652 21.6858 73.331 22.892 73.2372 24.0921C73.0086 29.4442 71.2078 34.6102 68.0599 38.9446C64.9119 43.279 60.5564 46.5896 55.5375 48.4626"
      fill="#1B0134"
    />
    <path
      d="M50.9616 30.3941C51.5393 25.8705 51.6908 21.3026 51.4142 16.7506C51.1304 12.1692 49.562 7.76041 46.8887 4.02905C45.7715 2.42802 44.2265 1.17345 42.4303 0.408669C41.5173 0.0441407 40.526 -0.079456 39.5515 0.0496946C38.577 0.178845 37.6521 0.556407 36.8656 1.14616C36.2004 1.76989 35.6834 2.53483 35.3527 3.38471C35.022 4.23459 34.8861 5.14777 34.9549 6.05712C35.1225 7.86781 35.5986 9.63652 36.3628 11.2866C39.715 19.2983 45.2461 28.1649 51.0454 30.3941"
      fill="#1B0134"
    />
    <path
      d="M105.401 40.9202C105.362 41.2934 105.362 41.6699 105.401 42.0431C105.401 42.8644 105.401 43.9204 105.401 45.2445C105.401 46.5686 105.401 48.3453 105.318 50.2728C105.234 52.2003 105.318 54.4965 105.083 56.9772C104.865 62.0055 104.379 67.8383 103.625 74.3751C102.871 80.9119 101.597 88.0521 100.071 95.5107C98.5462 102.969 96.7193 109.925 94.7583 116.227C92.7972 122.529 90.8865 128.094 89.093 132.737C88.255 135.067 87.4169 137.145 86.6292 138.972C85.8414 140.799 85.2212 142.324 84.6514 143.648L83.411 146.598C83.2475 146.938 83.1129 147.291 83.0088 147.654C83.2242 147.343 83.4094 147.012 83.5619 146.665C83.9139 145.928 84.3664 144.989 84.9531 143.782C85.5397 142.576 86.2772 140.983 87.0482 139.156C87.8192 137.329 88.7243 135.285 89.6461 132.955C91.5066 128.329 93.5012 122.731 95.479 116.445C97.4568 110.16 99.2837 103.12 100.876 95.6783C102.468 88.2364 103.507 81.0628 104.228 74.5092C104.949 67.9557 105.368 62.039 105.502 57.0442C105.636 54.5636 105.603 52.3009 105.619 50.3398C105.636 48.3788 105.619 46.6524 105.619 45.3115C105.619 43.9707 105.619 42.9315 105.502 42.1102C105.517 41.7109 105.483 41.3113 105.401 40.9202Z"
      fill="#1B0134"
    />
    <path
      d="M103.441 77.1237C103.356 72.815 101.78 68.6688 98.9822 65.391C98.5707 64.7876 98.0183 64.2937 97.3728 63.952C96.7274 63.6103 96.0083 63.4311 95.278 63.4299C93.3505 63.7149 92.4789 66.0111 92.4119 67.9554C92.3093 71.2566 93.4047 74.4834 95.4959 77.0399C97.2795 79.497 99.9591 81.153 102.955 81.6491"
      fill="#1B0134"
    />
    <path
      d="M99.2 100.656C99.4346 95.6281 97.8088 87.784 94.8588 83.6776C94.4175 83.0127 93.8476 82.4428 93.1827 82.0015C92.846 81.7819 92.4603 81.6487 92.0598 81.6136C91.6593 81.5785 91.2563 81.6426 90.8865 81.8003C90.4128 82.1236 90.03 82.5631 89.7747 83.0766C89.5194 83.5902 89.4001 84.1606 89.4283 84.7335C89.1462 88.3857 89.8856 92.0445 91.5639 95.3005C93.2423 98.5565 95.7933 101.281 98.9318 103.171"
      fill="#1B0134"
    />
    <path
      d="M93.7023 120.803C93.1977 115.74 91.352 110.903 88.3555 106.791C88.0555 106.278 87.6565 105.831 87.1817 105.474C86.7069 105.117 86.1658 104.858 85.59 104.713C83.8133 104.478 82.3886 106.305 82.0031 108.065C81.1986 111.668 82.992 115.339 85.0704 118.406C87.062 120.973 89.3775 123.272 91.9592 125.245"
      fill="#1B0134"
    />
    <path
      d="M88.7408 134.983C87.9548 131.001 86.301 127.241 83.8969 123.971C83.3744 123.275 82.7049 122.702 81.9359 122.295C81.545 122.091 81.1062 121.998 80.6663 122.024C80.2264 122.051 79.8019 122.196 79.4385 122.446C78.3322 123.317 78.5837 125.044 79.053 126.385C80.5638 130.964 83.5005 134.941 87.4335 137.732"
      fill="#1B0134"
    />
    <path
      d="M91.037 129.267C95.5626 126.298 100.355 123.756 105.351 121.674C105.709 121.449 106.118 121.319 106.54 121.296C106.963 121.272 107.384 121.356 107.764 121.54C108 121.742 108.176 122.006 108.27 122.302C108.365 122.598 108.376 122.915 108.301 123.216C108.104 123.843 107.766 124.417 107.312 124.892C105.111 127.362 102.38 129.304 99.3244 130.572C96.2686 131.84 92.966 132.402 89.6626 132.217"
      fill="#1B0134"
    />
    <path
      d="M96.4846 110.545C100.418 107.997 104.329 105.438 108.217 102.868C109.302 102.087 110.524 101.518 111.821 101.192C112.465 100.969 113.163 100.955 113.816 101.153C114.468 101.35 115.042 101.749 115.455 102.291C115.867 102.834 116.098 103.493 116.114 104.175C116.129 104.856 115.929 105.525 115.542 106.086C114.838 107.23 113.901 108.212 112.793 108.969C110.338 111.002 107.532 112.57 104.513 113.595C103.167 114.168 101.719 114.463 100.256 114.463C98.7928 114.463 97.3448 114.168 95.9985 113.595"
      fill="#1B0134"
    />
    <path
      d="M101.932 89.2253C105.172 84.7882 109.525 81.2845 114.553 79.0681C115.372 78.6531 116.283 78.4513 117.201 78.4815C118.09 78.6874 118.879 79.1967 119.433 79.9218C119.986 80.6468 120.27 81.5422 120.235 82.4538C120.206 83.4799 119.963 84.4886 119.522 85.4152C119.08 86.3418 118.449 87.1657 117.67 87.8341C116.111 89.1706 114.298 90.1796 112.34 90.8008C108.782 92.0865 105.043 92.7993 101.261 92.9127"
      fill="#1B0134"
    />
    <path
      d="M104.446 69.4639C107.161 63.7148 111.871 59.6922 117.034 55.9712C117.515 55.5049 118.111 55.1729 118.761 55.0082C119.41 54.8435 120.092 54.8519 120.738 55.0326C121.112 55.2421 121.428 55.5421 121.656 55.9052C121.885 56.2683 122.018 56.6829 122.045 57.111C122.085 57.9708 121.936 58.8289 121.609 59.6252C120.301 63.22 117.963 66.3499 114.886 68.6242C111.81 70.8985 108.133 72.2166 104.312 72.4138"
      fill="#1B0134"
    />
    <path
      d="M105.083 59.1729C106.449 56.2145 107.532 53.1335 108.318 49.9712C109.115 46.791 108.998 43.4507 107.983 40.3336C107.535 39.0244 106.735 37.8644 105.67 36.9814C105.126 36.535 104.476 36.2359 103.784 36.1126C103.091 35.9893 102.378 36.0461 101.714 36.2774C101.128 36.5594 100.613 36.9694 100.207 37.4771C99.8009 37.9848 99.514 38.5772 99.3674 39.2106C99.0768 40.4746 99.02 41.7812 99.1998 43.0656C99.7362 49.2337 101.613 56.4744 105.083 59.2568"
      fill="#1B0134"
    />
    <path
      d="M16.6353 40.1487C16.6102 40.5226 16.6102 40.8977 16.6353 41.2716C16.6353 42.0929 16.5515 43.1489 16.518 44.473C16.4844 45.7971 16.4174 47.5738 16.518 49.5013C16.6185 51.4288 16.518 53.7418 16.6353 56.2057C16.7861 61.234 17.2052 67.1171 17.9091 73.6707C18.6131 80.2242 19.7863 87.3979 21.2613 94.8398C22.7363 102.282 24.7141 109.305 26.6416 115.607C28.5691 121.909 30.614 127.49 32.4912 132.116C33.3795 134.446 34.3014 136.491 35.0891 138.318C35.8769 140.145 36.5641 141.67 37.1843 142.944L38.5754 145.827C38.7191 146.174 38.8988 146.506 39.1118 146.816C39.0098 146.455 38.881 146.102 38.7263 145.76L37.4022 142.877C36.8323 141.603 36.1618 140.044 35.4411 138.201C34.7204 136.357 33.765 134.295 32.9605 131.965C31.1671 127.323 29.2396 121.724 27.2953 115.456C25.351 109.187 23.5576 102.164 21.982 94.756C20.4065 87.3476 19.3338 80.1907 18.5125 73.6707C17.6912 67.1506 17.289 61.234 17.0711 56.2057C16.9035 53.7251 16.8867 51.4791 16.8197 49.5013C16.7526 47.5235 16.8197 45.8139 16.8197 44.473C16.8197 43.1321 16.8197 42.0929 16.8197 41.2716C16.8059 40.891 16.7439 40.5137 16.6353 40.1487Z"
      fill="#5B4074"
    />
    <path
      d="M18.5962 76.3195C18.6804 72.0076 20.2626 67.8594 23.0714 64.5868C23.4781 63.9827 24.0272 63.4878 24.6702 63.1458C25.3132 62.8039 26.0305 62.6252 26.7588 62.6257C28.6863 62.9107 29.5579 65.2069 29.6249 67.1512C29.7543 70.479 28.6635 73.7395 26.5577 76.3195C24.7688 78.7791 22.0832 80.435 19.0823 80.9288"
      fill="#5B4074"
    />
    <path
      d="M22.8368 99.8855C22.6021 94.8572 24.2279 87.013 27.1779 82.9066C27.6203 82.2425 28.19 81.6729 28.854 81.2305C29.1876 81.011 29.5705 80.8777 29.9684 80.8426C30.3662 80.8075 30.7665 80.8717 31.1335 81.0293C31.6071 81.3527 31.9899 81.7922 32.2452 82.3057C32.5005 82.8193 32.6198 83.3897 32.5917 83.9625C32.8777 87.6153 32.14 91.2755 30.4613 94.5323C28.7826 97.789 26.2293 100.513 23.0882 102.4"
      fill="#5B4074"
    />
    <path
      d="M28.3345 120.032C28.8527 114.969 30.7034 110.135 33.698 106.02C33.9954 105.508 34.3915 105.061 34.8635 104.704C35.3354 104.348 35.8736 104.088 36.4468 103.942C38.2402 103.707 39.6481 105.534 40.0504 107.294C40.8549 110.897 39.0615 114.585 36.9664 117.635C34.9794 120.207 32.6634 122.506 30.0776 124.474"
      fill="#5B4074"
    />
    <path
      d="M33.3125 134.195C34.0929 130.231 35.7478 126.492 38.1564 123.25C38.6728 122.554 39.3368 121.982 40.1007 121.574C40.4915 121.37 40.9304 121.276 41.3703 121.303C41.8102 121.33 42.2346 121.475 42.5981 121.724C43.7043 122.596 43.4697 124.339 43.0003 125.663C41.4821 130.239 38.547 134.214 34.6199 137.011"
      fill="#5B4074"
    />
    <path
      d="M31.1 128.496C26.5686 125.527 21.7711 122.985 16.7693 120.903C16.4113 120.679 16.002 120.548 15.5799 120.525C15.1577 120.501 14.7365 120.585 14.3558 120.769C14.1074 120.98 13.926 121.259 13.8337 121.572C13.7414 121.884 13.7423 122.217 13.8362 122.529C14.0265 123.155 14.3593 123.729 14.8083 124.205C17.0104 126.674 19.7409 128.615 22.7965 129.883C25.8522 131.151 29.1544 131.714 32.4576 131.53"
      fill="#5B4074"
    />
    <path
      d="M25.5518 109.841L13.8191 102.165C12.7389 101.386 11.5222 100.818 10.2323 100.488C9.58298 100.319 8.89959 100.333 8.25768 100.528C7.61578 100.724 7.04038 101.093 6.59514 101.595C6.22523 102.154 6.02107 102.806 6.00624 103.477C5.99141 104.147 6.16652 104.808 6.51134 105.383C7.20565 106.484 8.11837 107.431 9.1931 108.165C11.6509 110.194 14.4563 111.762 17.473 112.791C18.8222 113.364 20.2729 113.659 21.7387 113.659C23.2045 113.659 24.6552 113.364 26.0044 112.791"
      fill="#5B4074"
    />
    <path
      d="M20.1046 88.4545C16.872 84.0102 12.5164 80.5048 7.4836 78.2974C6.67019 77.8824 5.76478 77.6805 4.85212 77.7107C3.96183 77.9163 3.17069 78.425 2.61418 79.1496C2.05768 79.8743 1.77042 80.7699 1.80161 81.6831C1.83014 82.7092 2.07304 83.7179 2.51468 84.6445C2.95633 85.5711 3.58697 86.3951 4.36605 87.0634C5.92562 88.4 7.73829 89.4089 9.69605 90.0301C13.2541 91.3158 16.9936 92.0286 20.7751 92.142"
      fill="#5B4074"
    />
    <path
      d="M17.6074 68.6932C14.8753 62.9441 10.1655 58.9215 5.0031 55.2005C4.52236 54.7327 3.92697 54.3998 3.27675 54.235C2.62654 54.0702 1.94441 54.0795 1.29892 54.2619C0.928688 54.4746 0.616994 54.7758 0.391756 55.1385C0.166518 55.5012 0.03477 55.9141 0.0083254 56.3403C-0.0373995 57.1989 0.105632 58.057 0.42735 58.8544C1.73775 62.448 4.07648 65.5768 7.15223 67.8508C10.228 70.1248 13.9048 71.4437 17.7247 71.6431"
      fill="#5B4074"
    />
    <path
      d="M16.9701 58.4016C15.599 55.4435 14.5102 52.3626 13.7185 49.1998C12.9272 46.0181 13.0492 42.6777 14.0705 39.5622C14.4855 38.2044 15.2897 36.998 16.3835 36.0927C16.924 35.6463 17.5705 35.347 18.2605 35.2237C18.9505 35.1004 19.6607 35.1572 20.3223 35.3887C20.9106 35.6697 21.4283 36.0792 21.8373 36.5868C22.2463 37.0944 22.5362 37.6873 22.6856 38.3219C22.9596 39.5881 23.0162 40.8917 22.8533 42.1769C22.3001 48.3618 20.4397 55.5857 16.9701 58.3681"
      fill="#5B4074"
    />
    <path
      d="M120.402 146.883V161.8C120.402 162.568 120.251 163.329 119.957 164.039C119.663 164.748 119.232 165.393 118.689 165.936C118.146 166.48 117.501 166.91 116.791 167.204C116.082 167.498 115.321 167.65 114.553 167.65H12.8974C12.1293 167.65 11.3686 167.498 10.6589 167.204C9.94919 166.91 9.30434 166.48 8.76116 165.936C8.21797 165.393 7.78709 164.748 7.49312 164.039C7.19915 163.329 7.04785 162.568 7.04785 161.8V146.883H120.402Z"
      fill="#502C3B"
    />
    <g opacity="0.4">
      <path
        d="M111.586 167.583L110.748 172.309L16.9538 178.997L14.5737 167.583H111.586Z"
        fill="black"
      />
    </g>
  </svg>
);
