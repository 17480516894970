import { useState } from "react";
import { useTheme } from "../../context/ThemeContext";
import { useNavigate } from "react-router-dom";
import React from "react";
import { getPayers, sendInsuranceData } from "../../utils/utils";
import SmartDropdown from "../insurance/SmartDropDown";
import LoadingOverlay from "../insurance/LoadingOverlay";
const ChatForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
    insuranceCompany: "",
    insuranceMemberId: "",
    isInsuranceExist: true
  });

  const [hasInsurance, setHasInsurance] = useState(true);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [payers, setpayers] = useState([]);
  
  React.useEffect(() => {
    if(!hasInsurance) {
      setFormData((prev) => ({ ...prev, insuranceCompany: '', insuranceMemberId: '', isInsuranceExist: false, dob: '' }));
    } else {
      setFormData((prev) => ({ ...prev, isInsuranceExist: true }));
    }
  }, [hasInsurance]);
  const { theme } = useTheme();
  React.useEffect(() => {
    setLoading(true);
    const response = getPayers();
    response
      .then((responseData) => {
        const mapData = responseData.map((data: any) => data.payerName);
        setpayers(mapData || []);
        setLoading(false);
      })
      .catch((error) => {
        setpayers([]);
        setLoading(false);
        // setMessage({
        //   type: 'error',
        //   text: 'Something went wrong. Please try after sometime',
        // });
      });
  }, []);

  const validate = () => {
    const newErrors: Record<string, string> = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required.";
    if (!formData.lastName) newErrors.lastName = "Last Name is required.";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is not valid.";
    }
    if (hasInsurance && !formData.dob) newErrors.dob = "Date Of Birth is required.";
    if (hasInsurance && !formData.insuranceCompany)
      newErrors.insuranceCompany = "Insurance Company is required.";
    if (hasInsurance && !formData.insuranceMemberId)
      newErrors.insuranceMemberId = "Insurance Member ID is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const apiPromise = sendInsuranceData(formData);
      apiPromise.then((response) => {
          window.location.href = "/asksandra";
          setLoading(false);
      }).catch((error) => {
        window.location.href = "/asksandra";
        setLoading(false);
      });
    }
  };

  const onInsuranceChange = (value: string) => {
    setFormData((prev) => ({ ...prev, insuranceCompany: value }));
  };

  return (
    <div className="flex justify-center items-center mt-5 w-full p-4">
      <form
        className="w-full"
        onSubmit={handleSubmit}
      >
        {
          loading && <LoadingOverlay />
        }
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 w-full">
          <div>
            <div>
              <label
                htmlFor="firstName"
                className="block text-[16px] font-[600] leading-[20.16px] text-[#1B0134] font-[Plus Jakarta Sans] mb-2"
              >
                First Name*
              </label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                value={formData.firstName}
                onChange={handleChange}
                className="w-full  h-[48px] p-[12px_16px] gap-2 border-[1px] border-[#DCE1E8] bg-white opacity-100 rounded-[12px]"
                placeholder="Enter your first name"
              />
            </div>

            {errors.firstName && (
              <p className="text-sm text-red-500 mt-1">{errors.firstName}</p>
            )}
          </div>
          <div>
            <label htmlFor="lastName" className="block text-[16px] font-[600] leading-[20.16px] text-[#1B0134] font-[Plus Jakarta Sans] mb-2">
              Last Name*
            </label>
            <input
              id="lastName"
              name="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full  h-[48px] p-[12px_16px] gap-2 border-[1px] border-[#DCE1E8] bg-white opacity-100 rounded-[12px]"
              placeholder="Enter your last name"
            />
            {errors.lastName && (
              <p className="text-sm text-red-500 mt-1">{errors.lastName}</p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <div>
            <label htmlFor="email" className="block text-[16px] font-[600] leading-[20.16px] text-[#1B0134] font-[Plus Jakarta Sans] mb-2">
              Email*
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full  h-[48px] p-[12px_16px] gap-2 border-[1px] border-[#DCE1E8] bg-white opacity-100 rounded-[12px]"
              placeholder="Enter your email"
            />
            {errors.email && <p className="text-sm text-red-500 mt-1">{errors.email}</p>}
          </div>
          <div>
            <label htmlFor="phone" className="block text-[16px] font-[600] leading-[20.16px] text-[#1B0134] font-[Plus Jakarta Sans] mb-2">
              Phone
            </label>
            <input
              id="phone"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              className="w-full  h-[48px] p-[12px_16px] gap-2 border-[1px] border-[#DCE1E8] bg-white opacity-100 rounded-[12px]"
              placeholder="Enter your phone"
            />
          </div>
        </div>

        <div className="flex items-center mb-4">
          <label className="block text-[16px] font-[600] leading-[20.16px] text-[#1B0134] font-[Plus Jakarta Sans] mb-2 mr-2">
            I don't have insurance*</label>
          <input
            type="checkbox"
            checked={!hasInsurance}
            onChange={() => setHasInsurance(!hasInsurance)}
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 mb-1"
          />
        </div>

        {hasInsurance && (
          <div>
            <div className="mb-4">
              <label htmlFor="insuranceMemberId" className="block text-[16px] font-[600] leading-[20.16px] text-[#1B0134] font-[Plus Jakarta Sans] mb-2">
                Date Of Birth
              </label>
              <input
                id="dob"
                name="dob"
                type="date"
                value={formData.dob}
                onChange={handleChange}
                className="w-full h-[48px] p-[12px_16px] gap-2 border-[1px] border-[#DCE1E8] bg-white opacity-100 rounded-[12px]"
                placeholder="Enter Date Of Birth"
              />
              {errors.dob && (
                <p className="text-sm text-red-500 mt-1">{errors.dob}</p>
              )}
            </div>
            <div className="mb-4">
              <SmartDropdown 
                options={payers} 
                onSelect={onInsuranceChange} 
                labelStyles="text-[16px] font-[600] leading-[20.16px] text-[#1B0134] font-[Plus Jakarta Sans] mb-2"
                inputStyles="w-full h-[48px] p-[12px_16px] gap-2 border-[1px] border-[#DCE1E8] bg-white opacity-100 rounded-[12px]"
              />
              {errors.insuranceCompany && (
                <p className="text-sm text-red-500 mt-1">{errors.insuranceCompany}</p>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor="insuranceMemberId" className="block text-[16px] font-[600] leading-[20.16px] text-[#1B0134] font-[Plus Jakarta Sans] mb-2">
                Insurance Member ID
              </label>
              <input
                id="insuranceMemberId"
                name="insuranceMemberId"
                type="text"
                value={formData.insuranceMemberId}
                onChange={handleChange}
                className="w-full h-[48px] p-[12px_16px] gap-2 border-[1px] border-[#DCE1E8] bg-white opacity-100 rounded-[12px]"
                placeholder="Enter insurance member ID"
              />
              {errors.insuranceMemberId && (
                <p className="text-sm text-red-500 mt-1">{errors.insuranceMemberId}</p>
              )}
            </div>
          </div>
        )}

        <button
          type="submit"
          className={`w-full text-white text-sm md:text-lg font-bold py-2 rounded-[16px] ${theme['button-bg']}`}
        >
          Start Chatting
        </button>
      </form>
    </div>
  );
};

export default ChatForm;
