// import React from 'react';
// import MagicStar from '../../assets/SVGs/MagicStar';
import SandraDoctorWithWhiteBackground from '../../assets/SVGs/SandraDoctorWithWhiteBackground';
import MultipleMagicStar from '../../assets/SVGs/MultipleMagicStar';
import { useTheme } from '../../context/ThemeContext';

export default function MgicStartWithSandraDocIcon() {
  const { theme } = useTheme();
  return (
    <div className="w-auto h-auto relative">
      <div>
        <SandraDoctorWithWhiteBackground color={theme.p_color} />
      </div>
      <div className="absolute top-16 left-[-15px] ">
        <MultipleMagicStar
          stopColor={theme['star-stopColor']}
          offsetStopColor={theme['star-stopOffsetColor']}
        />
      </div>
    </div>
  );
}
