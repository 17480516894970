import React from 'react';

const ArrowIcon = ({
  color = 'teal',
  width = '14',
  height = '14',
  style = {},
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M7.00008 0.333496L5.82508 1.5085L10.4751 6.16683L0.333415 6.16683L0.333415 7.8335L10.4751 7.8335L5.81675 12.4835L7.00008 13.6668L13.6667 7.00016L7.00008 0.333496Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowIcon;
