export interface Theme {
  logo: string;
  client: string;
  border: string;
  bg: string;
  'bg-default': string;
  'home-subheading': string;
  'home-heading-text': string;
  'home-button-text': string;
  'home-button-border': string;
  'button-bg': string;
  'action-button-bg': string;
  'text-600': string;
  'text-default-800': string;
  color: string;
  'star-stopColor': string;
  'star-stopOffsetColor': string;
  p_color: string;
  text_sub_heading?: string;
  s_color: string;
  gradient: string;
}
export const THEMES: Record<string, Theme> = {
  default: {
    logo: 'PatientBud',
    client: "patientbud",
    text_sub_heading:
      'PatientBud simplifies healthcare billing—get clear, reliable answers in seconds.',
    color: 'teal',
    bg: '#0699A1',
    p_color: '#08B4BD',
    s_color: '#242E49',
    border: 'border-teal-300',
    'star-stopColor': '#42CBD2',
    'star-stopOffsetColor': '#0699A1',
    'bg-default': 'bg-[#F2F5F9]',
    'home-subheading': 'text-teal-40',
    'home-heading-text': 'text-gray-70',
    'button-bg': 'bg-teal-40',
    'home-button-border': 'border-teal-40',
    'home-button-text': 'text-teal-40',
    'action-button-bg': 'bg-teal-50 hover:bg-teal-100',
    'text-600': 'text-teal-600',
    'text-default-800': 'text-gray-800',
    gradient: 'bg-teal-gradient',
  },
  ovum: {
    logo: 'Ovum',
    client: "ovum",
    text_sub_heading:
      'Hi, I am Sandra at Ovum! Ask me any insurance, offers, pricing, estimate or bill related question!',
    color: 'orange',
    bg: '#351C48',
    p_color: '#502C3B',
    s_color: '#5B4074',
    border: 'border-orange-300',
    'star-stopColor': '#8D5B6F',
    'star-stopOffsetColor': '#351C48',
    'bg-default': 'bg-[#F8F9FC]',
    'home-subheading': 'text-[#502C3B]',
    'button-bg': 'bg-orange',
    'home-button-text': 'text-orange',
    'home-button-border': 'border-orange',
    'home-heading-text': 'text-gray-900',
    'action-button-bg': 'bg-orange',
    'text-600': 'text-[#502C3B]',
    'text-default-800': 'text-gray-800',
    gradient: 'bg-orange-gradient',
  },
  abchospital: {
    logo: 'AbcHospital',
    client: "abchospital",
    text_sub_heading:
      'Abc Hospital simplifies healthcare billing—get clear, reliable answers in seconds.',
    color: 'teal',
    bg: '#0699A1',
    p_color: '#08B4BD',
    s_color: '#242E49',
    border: 'border-teal-300',
    'star-stopColor': '#42CBD2',
    'star-stopOffsetColor': '#0699A1',
    'bg-default': 'bg-[#F2F5F9]',
    'home-subheading': 'text-teal-40',
    'home-heading-text': 'text-gray-70',
    'button-bg': 'bg-teal-40',
    'home-button-border': 'border-teal-40',
    'home-button-text': 'text-teal-40',
    'action-button-bg': 'bg-teal-50 hover:bg-teal-100',
    'text-600': 'text-teal-600',
    'text-default-800': 'text-gray-800',
    gradient: 'bg-teal-gradient',
  },
};
